import React from 'react';
import './SuccessModal.css';

const SuccessModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Thank You!</h2>
        <p>Your order was successfully submitted. Our design team will contact you with the first mockups within 3 business days.</p>
        <button className="modal-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
