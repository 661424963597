import React, { useState } from 'react';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const [userUID, setUserUID] = useState(null);

  const handleLoginSuccess = (token, userUID) => {
    setIsLoggedIn(true);
    setAuthToken(token);
    setUserUID(userUID);
  };

  return (
    <div>
      {isLoggedIn ? (
        <Dashboard token={authToken} userUID={userUID} />
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
};

export default App;
