import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProjectForm.css';
import FileUploadScreen from './FileUploadScreen';
import logo from './logo.png'; // Ensure you have your logo file in the appropriate directory

const ProjectForm = ({ token, userUID, onProjectCreated }) => {
  const [customer, setCustomer] = useState('');
  const [project, setProject] = useState('');
  const [design, setDesign] = useState('');
  const [revision, setRevision] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [projectName, setProjectName] = useState('');
  const [designOptions, setDesignOptions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [projectUID, setProjectUID] = useState(null);

  useEffect(() => {
    const updateDesignOptions = () => {
      switch (customer) {
        case 'Penn Entertainment':
          setDesignOptions(['Premier', 'Movie theater', 'Theme Park', 'Secret Venue']);
          break;
        case 'Gordon Ramsay':
          setDesignOptions(['Dinner', 'Award', 'Hell\'s Kitchen', 'Secret Venue']);
          break;
        case 'SSP America':
        case 'Holiday Inn':
          setDesignOptions(['Premier', 'Movie theater', 'Theme Park', 'Secret Venue', 'Dinner', 'Award', 'Hell\'s Kitchen']);
          break;
        default:
          setDesignOptions([]);
      }
    };

    updateDesignOptions();
  }, [customer]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const payload = {
      projectName,
      projectOwnersUIDS: [userUID],
      customer,
      project,
      design,
      revision,
      dueDate,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/project`,
        payload,
        { headers: headers }
      );

      const projectUID = response.data.result.project.projectUID;
      setProjectUID(projectUID);
      setLoading(false);

      onProjectCreated(projectUID);
    } catch (error) {
      setError('Failed to create project.');
      setLoading(false);
    }
  };

  if (projectUID) {
    return <FileUploadScreen projectUID={projectUID} token={token} userUID={userUID} />;
  }

  return (
    <div className="outer-container">
      <div className="left-side">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="right-side">
        <form onSubmit={handleSubmit} className="project-form">
          <div className="form-group">
            <label>Client Name</label>
            <select value={customer} onChange={(e) => setCustomer(e.target.value)} required>
              <option value="">Select Client Name</option>
              <option value="SSP America">SSP America</option>
              <option value="Penn Entertainment">Penn Entertainment</option>
              <option value="Holiday Inn">Holiday Inn</option>
              <option value="Gordon Ramsay">Gordon Ramsay</option>
            </select>
          </div>

          <div className="form-group">
            <label>Product Type</label>
            <select value={project} onChange={(e) => setProject(e.target.value)} required>
              <option value="">Select Product Type</option>
              <option value="Menu">Menu</option>
              <option value="Booklet">Booklet</option>
              <option value="Sticker">Sticker</option>
              <option value="Banner">Banner</option>
              <option value="Brochure">Brochure</option>
            </select>
          </div>

          <div className="form-group">
            <label>Venue Type</label>
            <select value={design} onChange={(e) => setDesign(e.target.value)} required>
              <option value="">Select Venue Type</option>
              {designOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Spirit</label>
            <select value={revision} onChange={(e) => setRevision(e.target.value)} required>
              <option value="">Select Spirit</option>
              <option value="Gin">Gin</option>
              <option value="Whiskey">Whiskey</option>
              <option value="Martini">Martini</option>
              <option value="Tequila">Tequila</option>
              <option value="Rum">Rum</option>
              <option value="Vodka">Vodka</option>
            </select>
          </div>

          <div className="form-group">
            <label>Due Date</label>
            <input type="date" value={dueDate} onChange={(e) => setDueDate(e.target.value)} required />
          </div>

          <div className="form-group">
            <label>Order Name</label>
            <input
              type="text"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              required
            />
          </div>

          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? (
              <div className="spinner"></div> /* Show spinner when loading */
            ) : (
              'Submit Order'
            )}
          </button>
          {error && <p className="error-message">{error}</p>}
        </form>
      </div>
    </div>
  );
};

export default ProjectForm;
