import React, { useState } from 'react';
import axios from 'axios';
import SuccessModal from './SuccessModal';
import './FileUploadScreen.css';

const FileUploadScreen = ({ projectUID, userUID, token, onComplete }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
    setMessage('');
    setIsUploadComplete(false);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      setMessage('Please select at least one file to upload.');
      return;
    }

    setUploading(true);
    setMessage('');
    setUploadProgress(0);

    try {
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const formData = new FormData();
        formData.append('uploadedFile', file, file.name);

        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        };

        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/refdoc/upload?ProjectUID=${projectUID}&FileName=${encodeURIComponent(file.name)}`,
          formData,
          {
            headers: headers,
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        );
      }

      setUploading(false);
      setUploadProgress(100);
      setIsUploadComplete(true);
      setMessage('Documentation uploaded successfully! You can upload additional files if needed.');
    } catch (error) {
      setUploading(false);
      setMessage('Failed to upload files.');
      console.error('Error uploading files:', error.response ? error.response.data : error.message);
    }
  };

  const handleCompleteOrder = () => {
    setShowSuccessModal(true);
  };

  const closeModalAndRedirect = () => {
    setShowSuccessModal(false);
    onComplete(); // Redirect to the dashboard
  };

  return (
    <div className="file-upload-container">
      <div className="file-upload-box">
        <div className="upload-icon">
          <i className="fas fa-cloud-upload-alt fa-3x"></i>
        </div>
        <p className="file-upload-instructions">
          Your order was created. Please upload the necessary documentation to submit the order.
        </p>

        <label htmlFor="file-upload" className="file-upload-label">
          Choose Files
          <input
            id="file-upload"
            type="file"
            multiple
            onChange={handleFileChange}
            className="file-input"
          />
        </label>

        {selectedFiles.length > 0 && (
          <div className="file-names">
            <p>Selected Files:</p>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
            <p className="upload-prompt">Please click "Upload Files" to continue.</p>
          </div>
        )}

        {selectedFiles.length > 0 && (
          <button onClick={handleUpload} className="upload-button" disabled={uploading}>
            {uploading ? `Uploading... ${uploadProgress}%` : 'Upload Files'}
          </button>
        )}

        {uploading && (
          <div className="progress-container">
            <progress value={uploadProgress} max="100" className="progress-bar" />
            <p>{uploadProgress}% completed</p>
          </div>
        )}

        {message && (
          <p
            className="upload-message"
            style={{ color: uploadProgress === 100 ? '#28a745' : 'red' }}
          >
            {message}
          </p>
        )}

        <div className="complete-order-container">
          <button
            onClick={handleCompleteOrder}
            className="complete-order-button"
            disabled={!isUploadComplete}
          >
            Complete Order
          </button>
        </div>
      </div>

      {/* Success Modal */}
      <SuccessModal show={showSuccessModal} onClose={closeModalAndRedirect} />
    </div>
  );
};

export default FileUploadScreen;
