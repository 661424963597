import React, { useState, useEffect, useCallback } from 'react';
import ProjectForm from './ProjectForm';
import FileUploadScreen from './FileUploadScreen';
import axios from 'axios';
import './Dashboard.css'; // Import custom styles

const Dashboard = ({ token, userUID }) => {
  const [view, setView] = useState('dashboard');
  const [currentProjectUID, setCurrentProjectUID] = useState(null);
  const [activeProjects, setActiveProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  const handleCreateProject = () => {
    setView('create');
  };

  const handleProjectCreated = (projectUID) => {
    setCurrentProjectUID(projectUID);
    setView('upload');
  };

  const handleCompleteOrder = () => {
    setView('dashboard'); // Navigate back to the dashboard after completing the order
    setCurrentProjectUID(null); // Reset the current project UID
  };

  const fetchActiveProjects = useCallback(async () => {
    setLoadingProjects(true);
    setFetchError(null); // Clear any previous errors
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/project`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          States: 'Active',
        },
      });

      console.log('API Response:', response.data);  // Log the full response for debugging

      // Extract the array of active projects from the response
      const projectsArray = response.data.result;
      if (Array.isArray(projectsArray)) {
        setActiveProjects(projectsArray);
      } else {
        setActiveProjects([]);  // Set to empty array if the data structure is unexpected
      }
    } catch (error) {
      setFetchError('Error fetching active projects');
      console.error('Error fetching active projects:', error);
    } finally {
      setLoadingProjects(false);
    }
  }, [token]);

  useEffect(() => {
    fetchActiveProjects();
  }, [fetchActiveProjects]);

  return (
    <div className="dashboard-container">
      {view === 'dashboard' && (
        <div className="dashboard-grid">
          <div className="welcome-box">
            <h2>Welcome to the client portal</h2>
            <p>Here you can manage your orders and view your project status.</p>
            <button className="dashboard-button" onClick={handleCreateProject}>
              Submit New Order
            </button>
          </div>
          <div className="orders-box">
            <h3>The list of active orders:</h3>
            {loadingProjects ? (
              <p className="loading-message">Loading active orders...</p>
            ) : fetchError ? (
              <p className="error-message">{fetchError}</p>
            ) : activeProjects.length > 0 ? (
              <div className="project-list-container">
                <ul className="project-list">
                  {activeProjects.map((project, index) => (
                    <li key={project.projectUID || index} className="project-item">
                      {project.name}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <p className="no-projects-message">No active orders found.</p>
            )}
          </div>
        </div>
      )}

      {view === 'create' && (
        <ProjectForm
          token={token}
          userUID={userUID}
          onProjectCreated={handleProjectCreated}
        />
      )}
      {view === 'upload' && currentProjectUID && (
        <FileUploadScreen 
           projectUID={currentProjectUID} 
           token={token} 
           userUID={userUID} 
           onComplete={handleCompleteOrder} 
        />
      )}
    </div>
  );
};

export default Dashboard;
